import React from 'react'
import { Typography, Link } from '@mui/material'

const Copyright = () => {
  return (
    <Typography variant="body2" align="center" mt={2}>
      {'Copyright © '}
      <Link color="inherit" href="https://bigkittylabs.com/">
         Big Kitty Labs
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default Copyright


