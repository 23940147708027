import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Button,
  CircularProgress,
  Skeleton,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  Tooltip,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import * as _ from 'lodash'

import isValidEmail from 'helpers/isValidEmail'
import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'
import CopyrightFooter from 'components/CopyrightFooter'

const SettingsPage = (props) => {
  const { user } = props
  const [searches, setSearches] = useState([])
  useEffect(() => {
    const storedSearches =
      JSON.parse(localStorage.getItem('userSearchHistory')) || []
    setSearches(storedSearches)
  }, [])

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')

  const [original, setOriginal] = useState({})

  const [loading, setLoading] = useState(false)

  const handleSave = async () => {
    setLoading(true)
    try {
      setTimeout(() => {
        props.setUser({
          ...user,
          email,
          username,
          firstName,
          lastName,
          companyName,
        })
        setLoading(false)
      }, 1000)
    } catch (e) {
      props.setSnackbar({ message: e.message, severity: 'error' })
    }
  }
  const handleCheckboxChange = (id) => {
    setSearches((prevSearches) =>
      prevSearches.map((search) =>
        search.id === id ? { ...search, selected: !search.selected } : search
      )
    )
  }
  const handleDelete = () => {
    const updatedSearches = searches.filter((search) => !search.selected)
    setSearches(updatedSearches)
    localStorage.setItem('userSearchHistory', JSON.stringify(updatedSearches))
  }

  useEffect(() => {
    if (user) {
      setEmail(user.email)
      setUsername(user.username)
      setFirstName(user.firstName)
      setLastName(user.lastName)
      setCompanyName(user.companyName)

      setOriginal({
        email: user.email,
        username: user.username,
        firstName: user.firstName,
        lastName: user.lastName,
        companyName: user.companyName,
      })
    }
  }, [user])

  return (
    <Box>
      <Typography
        variant="h4"
        mb={2}
        sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
        Settings
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <Typography
            variant="h6"
            mb={2}
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
            Account Management
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              mt: 2,
              alignItems: { xs: 'center', md: 'unset' },
            }}>
            <TextField
              label="Username"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {password !== '' && (
              <TextField
                label="Confirm New Password"
                type="password"
                variant="outlined"
                size="small"
                fullWidth
                error={confirmPassword !== password}
                sx={{ mb: 3, maxWidth: 600 }}
                InputProps={{
                  sx: {
                    backgroundColor: 'background.paper',
                  },
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            )}
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              label="First Name"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              label="Last Name"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ mb: 3, maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              label="Company Name"
              variant="outlined"
              size="small"
              fullWidth
              sx={{ maxWidth: 600 }}
              InputProps={{
                sx: {
                  backgroundColor: 'background.paper',
                },
              }}
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              px: { xs: 10, sm: 20, md: 'unset' },
              mt: 2,
              maxWidth: { md: 600 },
            }}>
            <Button
              disabled={
                loading ||
                password !== confirmPassword ||
                (!_.isEqual(original, {
                  email,
                  username,
                  firstName,
                  lastName,
                  companyName,
                }) &&
                  !isValidEmail(email)) ||
                (_.isEqual(original, {
                  email,
                  username,
                  firstName,
                  lastName,
                  companyName,
                }) &&
                  password === '')
              }
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSave}
              sx={{ display: 'flex', gap: 1 }}>
              {loading && <CircularProgress size={20} color="grey" />}
              {loading ? 'Saving...' : 'Save Changes'}
            </Button>
          </Box>
        </Box>
        <Box>
          <Typography variant="h6" mb={2} sx={{ fontWeight: 'bold' }}>
            Search Information
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Search Name</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {searches.map((search) => (
                  <TableRow key={search.id}>
                    <TableCell>
                      <Checkbox
                        checked={search.selected}
                        onChange={() => handleCheckboxChange(search.id)}
                      />
                    </TableCell>
                    <TableCell>{search.name}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete search">
                        <IconButton aria-label="delete" onClick={handleDelete}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            sx={{ mt: 2 }}>
            Delete Selected Searches
          </Button>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            variant="h6"
            mb={2}
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
            Plan Management
          </Typography>
          <Skeleton variant="rectangular" width="100%" height={150} />
        </Box>
      </Box>
      <CopyrightFooter />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
})

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage)
