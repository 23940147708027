import moment from 'moment'

const generateDateArray = (startDate) => {
  const startMoment = moment(startDate)
  const today = moment()
  const dates = []

  while (startMoment.isSameOrBefore(today, 'day')) {
    dates.push(startMoment.format('MMMM Do'))
    startMoment.add(1, 'days')
  }

  return dates
}

export default generateDateArray
