import axios from 'axios'
import { store } from 'store'

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

instance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.response && error.response.data && error.response.data.message)
      error.message = error.response.data.message

    return Promise.reject(error)
  }
)

export const authInstance = () => {
  const state = store.getState()
  const authToken = state.user.token
  instance.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  return instance
}

const authLogin = ({ email, password }) => {
  return instance.post(`/auth/login`, { email, password })
}

const authRegister = ({ email, password, username }) => {
  return instance.post(`/auth/register`, { email, password, username })
}

const rfpSbir = ({ dateFrom, dateTo, keywords,closed, rate, tenantId, rfpId,withDomains }) => {
  return authInstance().get(`/rfp/sbir`, {
    params: {
      date_from: dateFrom,
      date_to: dateTo,
      keywords,
      closed : closed,
      rate,
      tenant_id: tenantId,
      rfp_id: rfpId,
      with_Domains: withDomains,
    },
  })
}

const rfpSam = ({ dateFrom, dateTo, keywords, rate, tenantId, rfpId, withDomains }) => {
  
  return authInstance().get(`/rfp/sam`, {
    params: {
      keywords,
      rate,
      date_from: dateFrom,
      date_to: dateTo,
      tenant_id: tenantId,
      with_Domains: withDomains,
      rfp_id: rfpId,
    },
  })
}

const rfpFiles = ({ tenantId, formData }) => {
  return authInstance().post(`/rfp/files`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    params: {
      tenant_id: tenantId,
    },
  })
}

const tenantsRfpsGet = ({ tenantId }) => {
  return authInstance().get(`/tenants/${tenantId}/rfps/`)
}

const api = {
  auth: {
    login: authLogin,
    register: authRegister,
  },
  rfp: {
    files: rfpFiles,
    sbir: rfpSbir,
    sam: rfpSam,
  },
  tenants: {
    rfps: {
      get: tenantsRfpsGet,
    },
  },
}

export default api
