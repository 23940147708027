import React from 'react'
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { Snackbar, Alert, Box, Typography, Button } from '@mui/material'
import * as Sentry from '@sentry/react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

import LoginPage from 'pages/auth/Login'
import SignUpPage from 'pages/auth/SignUp'
import ForgotPasswordPage from 'pages/auth/ForgotPassword'

import DashboardPage from 'pages/Dashboard'
import MyProposalsPage from 'pages/MyProposals'
import RFPSearchAndManagementPage from 'pages/RFPSearchAndManagement'
import ProposalBuilderPage from 'pages/ProposalBuilder'
import HelpAndSupportPage from 'pages/HelpAndSupport'
import SettingsPage from 'pages/Settings'

import AuthLayout from 'layouts/Auth'
import NavigationLayout from 'layouts/Navigation'
import Loading from 'components/Loading'
import { setSnackbar } from 'reducers/ui'
import theme from 'theme'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

const App = (props) => {
  const { token, loading, snackbar } = props

  let routes = null

  if (token) {
    routes = (
      <NavigationLayout>
        <Switch>
          <Route exact path="/">
            <DashboardPage />
          </Route>
          <Route path="/my-proposals">
            <MyProposalsPage />
          </Route>
          <Route path="/rfp-search-and-management">
            <RFPSearchAndManagementPage />
          </Route>
          <Route path="/proposal-builder">
            <ProposalBuilderPage />
          </Route>
          <Route path="/help-and-support">
            <HelpAndSupportPage />
          </Route>
          <Route path="/settings">
            <SettingsPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </NavigationLayout>
    )
  } else {
    routes = (
      <AuthLayout>
        <Switch>
          <Route exact path="/">
            <LoginPage />
          </Route>
          <Route path="/sign-up">
            <SignUpPage />
          </Route>
          <Route path="/forgot-password">
            <ForgotPasswordPage />
          </Route>
          <Redirect to="/" />
        </Switch>
      </AuthLayout>
    )
  }

  const handleSnackbarClose = (e, reason) => {
    if (reason === 'clickaway') return
    props.setSnackbar({ open: false })
  }

  return (
    <Sentry.ErrorBoundary fallback={SentryFallbackComponent}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <Router>{routes}</Router>
          <Loading loading={loading} backdrop />
          <Snackbar
            open={snackbar.open}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}>
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severity}
              sx={{ width: '100%' }}>
              {snackbar.message}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  )
}

const SentryFallbackComponent = () => (
  <Box
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
    <Box>
      <Typography variant="h4">Oops! An error occured.</Typography>
      <Typography sx={{ mt: 2 }}>
        This error has been reported and will be addressed as soon as possible.
      </Typography>
      <Button
        variant="outlined"
        sx={{ mt: 4 }}
        onClick={() => {
          window.location.reload()
          return false
        }}>
        Reset
      </Button>
    </Box>
  </Box>
)

const mapStateToProps = (state) => {
  return {
    token: state.user.token,
    loading: state.ui.loading,
    snackbar: state.ui.snackbar,
  }
}

const mapDispatchToProps = {
  setSnackbar,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
