import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import CssBaseline from '@mui/material/CssBaseline'
import * as Sentry from '@sentry/react'
import pkg from '../package.json'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import 'index.css'

import App from './App'
import { store, persistor } from 'store'
import Loading from 'components/Loading'

Sentry.init({
  dsn: '',
  release: `${pkg.name}@${pkg.version}`,
  environment: process.env.NODE_ENV,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  normalizeDepth: 10,
  enabled: process.env.NODE_ENV !== 'development',
})

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <Provider store={store}>
    <CssBaseline />
    <PersistGate loading={<Loading loading backdrop />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
)
