import React from 'react'
import { Box } from '@mui/material'

const Auth = (props) => (
  <Box
    sx={{
      backgroundColor: 'secondary.light',
      height: '100vh',
      pt: 8,
    }}>
    {props.children}
  </Box>
)

export default Auth

