import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#879FFF',
      light: '#E3F2Fd',
      dark: '#64B5F6',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#CBD5E1',
      light: '#EFF3F4',
      dark: '#879FFF',
    },
    background: {
      default: '#EFF3F4',
      sideNav: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'Nunito, sans-serif', 
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          paddingLeft: '12px',
          paddingRight: '12px',
          boxShadow: 'none',
          textTransform: 'none',
        },
        contained: {
          paddingLeft: '24px',
          paddingRight: '24px',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
  },
});

export default theme;
