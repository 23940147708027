import { createSlice } from '@reduxjs/toolkit'

const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    loading: false,
    snackbar: {
      open: false,
      message: '',
      severity: 'info',
    },
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload
    },
    setSnackbar(state, action) {
      if (action.payload.message) action.payload.open = true
      state.snackbar = { ...state.snackbar, ...action.payload }
    },
  },
})

const { actions, reducer } = uiSlice

export const { setLoading, setSnackbar } = actions

export default reducer
