import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  Box,
  Skeleton,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material'
import { Line } from 'react-chartjs-2'
import { useTheme } from '@emotion/react'
import generateDateArray from 'helpers/generateDateArray'
import moment from 'moment'
import MailingListTable from 'components/MailingListTable'
import CopyrightFooter from 'components/CopyrightFooter'

const UsageChart = () => {
  const theme = useTheme()

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
        ticks: {
          callback: (value) => {
            return value + '%'
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        callbacks: {
          title: (xDatapoint) => {
            return xDatapoint.label
          },
          label: (yDatapoint) => {
            return yDatapoint.parsed.y + '% token usage for this cycle'
          },
        },
      },
    },
  }

  const labels = generateDateArray(moment().subtract(27, 'days'))

  const createGradualIncreasingRandomGenerator = (maxStep = 10) => {
    let lastNum = 0

    return () => {
      const minPossible = lastNum
      const maxIncrement = Math.min(maxStep, 100 - minPossible)
      lastNum = minPossible + Math.floor(Math.random() * maxIncrement)
      return lastNum
    }
  }

  const getIncreasingRandomNumber = createGradualIncreasingRandomGenerator()

  const data = {
    labels,
    datasets: [
      {
        label: 'Usage',
        data: labels.map(getIncreasingRandomNumber),
        borderColor: theme.palette.secondary.main,
        backgroundColor: theme.palette.secondary.light,
      },
    ],
  }

  return <Line options={options} data={data} />
}

const TrendsAndInsightsTable = () => {
  const createData = (
    id,
    score,
    releaseDate,
    openDate,
    applicationDueDate,
    closeDate,
    agency,
    branch,
    program,
    phase,
    solicitation,
    topicNumber
  ) => ({
    id,
    score,
    releaseDate,
    openDate,
    applicationDueDate,
    closeDate,
    agency,
    branch,
    program,
    phase,
    solicitation,
    topicNumber,
  })

  const rows = [
    createData(
      1,
      99,
      moment(),
      moment(),
      moment(),
      moment(),
      'DOD',
      'Army',
      'SBIR',
      'PHS2021-1',
      'W911NF-21-S-0001',
      '21.1'
    ),
    createData(
      2,
      Math.floor(Math.random() * 5) + 95,
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days'),
      moment().subtract(1, 'days'),
      'DOD',
      'Navy',
      'STTR',
      'PHS2022-2',
      'N00014-21-S-B001',
      '21.B'
    ),
    createData(
      3,
      Math.floor(Math.random() * 5) + 90,
      moment().subtract(2, 'days'),
      moment().subtract(2, 'days'),
      moment().subtract(2, 'days'),
      moment().subtract(2, 'days'),
      'DOD',
      'Air Force',
      'SBIR',
      'PHS2023-3',
      'FA8650-21-S-0001',
      '21.1'
    ),
    createData(
      4,
      Math.floor(Math.random() * 5) + 85,
      moment().subtract(3, 'days'),
      moment().subtract(3, 'days'),
      moment().subtract(3, 'days'),
      moment().subtract(3, 'days'),
      'DOD',
      'Army',
      'STTR',
      'PHS2024-4',
      'W911NF-21-S-0002',
      '21.A'
    ),
    createData(
      5,
      Math.floor(Math.random() * 5) + 80,
      moment().subtract(4, 'days'),
      moment().subtract(4, 'days'),
      moment().subtract(4, 'days'),
      moment().subtract(4, 'days'),
      'DOD',
      'Navy',
      'SBIR',
      'PHS2025-5',
      'N00014-21-S-B002',
      '21.B'
    ),
  ]

  const rowCells = (row) => (
    <>
      <TableCell align="left">{row.score}</TableCell>
      <TableCell align="right">
        {moment(row.releaseDate).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell align="right">
        {moment(row.openDate).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell align="right">
        {moment(row.applicationDueDate).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell align="right">
        {moment(row.closeDate).format('MM/DD/YYYY')}
      </TableCell>
      <TableCell align="right">{row.agency}</TableCell>
      <TableCell align="right">{row.branch}</TableCell>
      <TableCell align="right">{row.program}</TableCell>
      <TableCell align="right">{row.phase}</TableCell>
      <TableCell align="right">{row.solicitation}</TableCell>
      <TableCell align="right">{row.topicNumber}</TableCell>
    </>
  )

  const headCells = [
    { id: 'score', numeric: false, disablePadding: false, label: 'Score' },
    {
      id: 'releaseDate',
      numeric: false,
      disablePadding: false,
      label: 'Release Date',
    },
    {
      id: 'openDate',
      numeric: false,
      disablePadding: false,
      label: 'Open Date',
    },
    {
      id: 'applicationDueDate',
      numeric: false,
      disablePadding: false,
      label: 'Application Due Date',
    },
    {
      id: 'closeDate',
      numeric: false,
      disablePadding: false,
      label: 'Close Date',
    },
    { id: 'agency', numeric: false, disablePadding: false, label: 'Agency' },
    { id: 'branch', numeric: false, disablePadding: false, label: 'Branch' },
    { id: 'program', numeric: false, disablePadding: false, label: 'Program' },
    { id: 'phase', numeric: false, disablePadding: false, label: 'Phase' },
    {
      id: 'solicitation',
      numeric: false,
      disablePadding: false,
      label: 'Solicitation',
    },
    {
      id: 'topicNumber',
      numeric: true,
      disablePadding: false,
      label: 'Topic Number',
    },
  ]

  return (
    <TableContainer
      component={Paper}
      sx={{
        width: '100%',
        maxHeight: 400,
        height: 400,
        overflowX: 'auto',
        overflowY: 'hidden',
        '&::-webkit-scrollbar': {
          height: '8px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '10px',
          '&:hover': {
            backgroundColor: '#555',
          },
        },
        '&::-webkit-scrollbar-track': {
          borderRadius: '10px',
        },
      }}>
      <Table aria-label="trends and insights table" sx={{ minWidth: 700 }}>
        <TableHead>
          <TableRow>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}>
                {headCell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>{rowCells(row)}</TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const DashboardPage = (props) => {
  const [emails, setEmails] = useState([
    'dummyemail1@example.com',
    'dummyemail2@example.com',
  ])

  return (
    <Box>
      <Typography
        variant="h4"
        textAlign="center"
        mb={2}
        sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
        Dashboard
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box
              sx={{
                px: { xs: 2, sm: 10, md: 20, lg: 2 },
                py: 2,
                pt: 10,
                display: 'flex',
                alignItems: 'center',
                backgroundColor: 'white',
                height: 400,
                borderRadius: 2,
                position: 'relative',
                boxShadow: 1,
              }}>
              <Typography
                variant="h6"
                sx={{
                  position: 'absolute',
                  top: 15,
                  left: '50%',
                  transform: 'translate(-50%)',
                }}>
                Token Usage
              </Typography>
              <UsageChart />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <TrendsAndInsightsTable />
          </Grid>
        </Grid>

        <Box>
          <Typography
            variant="h6"
            mb={2}
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
            Your Current Plan
          </Typography>
          <Skeleton
            variant="rectangular"
            width="100%"
            height={150}
            sx={{ borderRadius: 2, backgroundColor: 'white' }}
          />
        </Box>
        <Box>
          <Typography
            variant="h6"
            mb={2}
            sx={{ fontWeight: 'bold', alignSelf: 'flex-start' }}>
            Organization Mailing List
          </Typography>
          <MailingListTable initialEmails={emails} />
        </Box>
      </Box>
      <CopyrightFooter/>
    </Box>
  )
}

const mapDispatchToProps = {}

export default connect(null, mapDispatchToProps)(DashboardPage)
