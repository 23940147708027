import React, { Fragment } from 'react'
import { Alert, Box, Backdrop, CircularProgress } from '@mui/material'

const Loading = ({
  loading,
  error,
  backdrop,
  loadingContainerStyle,
  children,
}) => {
  return backdrop ? (
    <Backdrop
      sx={{
        color: 'primary.main',
        zIndex: (theme) => theme.zIndex.drawer + 1000,
      }}
      open={loading}>
      <CircularProgress color="inherit" size={50} />
    </Backdrop>
  ) : loading ? (
    <Box
      sx={{
        color: 'primary.main',
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        ...loadingContainerStyle,
      }}>
      <CircularProgress color="inherit" size={50} />
    </Box>
  ) : (
    <Fragment>
      {error && <Alert severity="error">{error.message}</Alert>}
      {children}
    </Fragment>
  )
}

export default Loading
