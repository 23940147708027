import React, { useState } from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TextField,
  Button,
  IconButton,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

const MailingListTable = ({ initialEmails }) => {
  const [emails, setEmails] = useState(initialEmails)
  const [newEmail, setNewEmail] = useState('')

  const addEmail = () => {
    if (newEmail) {
      setEmails([...emails, newEmail])
      setNewEmail('')
    } else {
      alert('Please enter an email address.')
    }
  }

  const removeEmail = (emailToRemove) => {
    setEmails(emails.filter((email) => email !== emailToRemove))
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="mailing list table">
        <TableHead>
          <TableRow>
            <TableCell>Email Address</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {emails.map((email) => (
            <TableRow key={email}>
              <TableCell component="th" scope="row">
                {email}
              </TableCell>
              <TableCell align="right">
                <IconButton onClick={() => removeEmail(email)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell>
              <TextField
                label="Add new email"
                variant="outlined"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                fullWidth
              />
            </TableCell>
            <TableCell align="right">
              <Button variant="contained" color="primary" onClick={addEmail}>
                Add Email
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default MailingListTable
