import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Container,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Logo from 'assets/logo.png'

import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'
import Copyright from 'components/Copyright'
import api from 'lib/api'

const LoginPage = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const handleLogin = async () => {
    setLoading(true)
    try {
      const response = await api.auth.login({ email, password })

      props.setUser(response.data.user)
    } catch (e) {
      props.setSnackbar({ message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        backgroundColor: 'white',
        p: 1,
        borderRadius: 4,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <img
            src={Logo}
            alt="RFP Viewer Logo"
            width={30}
            height={30}
            style={{ marginRight: '8px' }}
          />
          <Typography variant="h4">RFP Viewer</Typography>
        </Box>

        <Typography variant="h6" sx={{ mb: 3 }}>
          Sign In
        </Typography>

        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            size="small"
            sx={{ mb: 3, minWidth: 350 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            size="small"
            fullWidth
            sx={{ mb: 3 }}
            InputProps={{
              sx: {
                backgroundColor: 'background.paper',
              },
            }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') handleLogin()
            }}
          />

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />

          <LoadingButton
            loading={loading}
            sx={{
              color: 'primary.contrastText',
              my: 1,
            }}
            color="secondary"
            variant="contained"
            onClick={handleLogin}>
            Login
          </LoadingButton>

          <Grid container sx={{ mt: 1 }}>
            <Grid item xs>
              <Link
                href="/forgot-password"
                variant="body2"
                color="secondary.dark">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="/sign-up" variant="body2" color="secondary.dark">
                Don't have an account? Sign Up
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Copyright sx={{ mt: 8 }} />
    </Container>
  )
}

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(LoginPage)
