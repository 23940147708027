import React, { useState } from 'react'
import { connect } from 'react-redux'
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Container,
  Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
import Logo from 'assets/logo.png'

import { setUser } from 'reducers/user'
import { setSnackbar } from 'reducers/ui'
import Copyright from 'components/Copyright'
import isValidEmail from 'helpers/isValidEmail'

const SignupPage = (props) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [username, setUsername] = useState('')
  const [loading, setLoading] = useState(false)

  const handleSignup = async () => {
    setLoading(true)
    try {
      props.setSnackbar({
        message:
          'RFP Finder is not currently accepting new user registrations.  Please try again later.',
        severity: 'info',
      })
    } catch (e) {
      props.setSnackbar({ message: e.message, severity: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        backgroundColor: 'white',
        p: 1,
        borderRadius: 4,
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <img
            src={Logo}
            alt="RFP Viewer Logo"
            width={30}
            height={30}
            style={{ marginRight: '8px' }}
          />
          <Typography variant="h4">RFP Viewer</Typography>
        </Box>

        <Typography variant="h6" sx={{ mb: 1 }}>
          Sign Up
        </Typography>

        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
          <Grid container>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                size="small"
                sx={{ mb: 2 }}
                InputProps={{
                  sx: {
                    backgroundColor: 'background.paper',
                  },
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                size="small"
                sx={{ mb: 2 }}
                InputProps={{
                  sx: {
                    backgroundColor: 'background.paper',
                  },
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                size="small"
                sx={{ mb: 2 }}
                InputProps={{
                  sx: {
                    backgroundColor: 'background.paper',
                  },
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                size="small"
                sx={{ mb: 2 }}
                error={confirmPassword !== password}
                InputProps={{
                  sx: {
                    backgroundColor: 'background.paper',
                  },
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox value="allowExtraEmails" color="primary" />}
                label="I want to receive inspiration, marketing promotions and updates via email."
              />
            </Grid>
          </Grid>
          <LoadingButton
            disabled={
              loading ||
              password !== confirmPassword ||
              username === '' ||
              email === '' ||
              !isValidEmail(email) ||
              password === '' ||
              confirmPassword === ''
            }
            loading={loading}
            sx={{
              color: 'primary.contrastText',
              my: 1,
            }}
            color="secondary"
            variant="contained"
            onClick={handleSignup}>
            Sign Up
          </LoadingButton>
          <Grid container justifyContent="flex-end" mt={1}>
            <Grid item>
              <Link href="/" variant="body2" color="secondary.dark">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Copyright sx={{ mt: 3 }} />
    </Container>
  )
}

const mapDispatchToProps = {
  setUser,
  setSnackbar,
}

export default connect(null, mapDispatchToProps)(SignupPage)
